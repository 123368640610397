import React, { createContext, useEffect, useState } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
export const SupportContext = createContext();

export const SupportProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [myChatScroll, setMyChatScroll] = useState(0);
  const [allChatScroll, setAllChatScroll] = useState(0);
  // console.log(myChatScroll);
  // console.log('myChatScroll FROM CONTEXT');

  useEffect(async () => {
    const userTemp = await reactLocalStorage.getObject("userData");
    setAdmin(userTemp);
    setModalInfo((prev) => ({
      ...prev,
      adminId: userTemp?._id,
      admin: userTemp,
    }));
  }, []);
  const openModal = (info) => {
    setModalInfo((prev) => ({ ...prev, ...info }));
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalInfo({
      adminId: admin?._id,
      admin: admin,
    });
  };
  const resetModal = () => {
    setModalInfo({
      adminId: admin?._id,
      admin: admin,
    });
  };
  return (
    <SupportContext.Provider
      value={{
        isOpen,
        modalInfo,
        openModal,
        closeModal,
        resetModal,
        allChatScroll,
        setAllChatScroll,
        myChatScroll,
        setMyChatScroll,
      }}
    >
      {children}
    </SupportContext.Provider>
  );
};
