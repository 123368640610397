import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import { Link } from "react-router-dom";

import {
  Card,
  Container,
  Row,
  Modal,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import { reactLocalStorage } from "reactjs-localstorage";
import ReactLoading from "react-loading";

import { MyChat } from "views/pages/Chat/myChat";
import { AllChat } from "views/pages/Chat/allChat";
import InBoxServiceProvider from "../../views/pages/Chat/inBoxFBHook";

import MessageServiceProvider from "../../views/pages/Chat/messageBoxFBHook";
import { adminService } from "../../views/pages/Chat/inBoxFBHook";
import { SupportContext } from "context/supportContext";
import "./index.css";
import { disableCursor } from "@fullcalendar/react";

const SupportModal = () => {
  const [tab, setTab] = useState(0);
  const [openMsgModal, setOpenMsgModal] = useState(false);
  const [myMessageId, setMyMessageId] = useState(null);
  const [allMessageId, setAllMessageId] = useState(null);
  const [myScroll, setMyScroll] = useState(null);
  const [allScroll, setAllScroll] = useState(null);
  const allChatRef = useRef(null);
  const myChatRef = useRef(null);
  const { openModal, modalInfo, closeModal, isOpen, resetModal } =
    useContext(SupportContext);
  const { adminId, job, user, admin } = modalInfo;
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    if (!user || !user._id) return;
    if (user?.adminId === adminId) {
      setAllMessageId(null);
      setMyMessageId(`${user?.adminId}-${user?._id}`);
    } else {
      setTab(1);
      setMyMessageId(null);
      setAllMessageId(`${user?.adminId}-${user?._id}`);
    }
    return () => {
      // setMyMessageId(null);
      // setAllMessageId(null);
      closeModal();
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  // console.log(myChatScroll);
  // console.log('myChatScroll');
  // console.log(myScroll);
  // console.log('myScroll');
  // console.log(allScroll);
  // console.log('allScroll');

  const handleAllScroll = () => {
    if (allChatRef.current) {
      allChatRef.current.scrollTop = allChatRef.current.scrollTop;
    }
  };
  const handleMyScroll = () => {
    if (myChatRef.current) {
      myChatRef.current.scrollTop = myChatRef.current.scrollTop;
    }
  };

  useLayoutEffect(() => {
    const myChatElement = myChatRef.current;
    const allChatElement = allChatRef.current;
    if (allChatElement) {
      allChatElement.addEventListener("scroll", handleAllScroll);
      return () => {
        allChatElement.removeEventListener("scroll", handleAllScroll);
      };
    }
    if (myChatElement) {
      myChatElement.addEventListener("scroll", handleMyScroll);
      return () => {
        myChatElement.removeEventListener("scroll", handleMyScroll);
      };
    }
  }, []);

  function handleModalClose() {
    closeModal();
  }
  return (
    <div className="pageCoverSupport" id="pageCoverSupport">
      <div
        className="darkpageCoverSupport"
        onClick={() => handleModalClose()}
      ></div>
      <div className="mainSupportModel">
        <div className="SupportBox">
          <div className="SupportBoxContainor">
            <button
              className="SuppInboxClose"
              onClick={() => handleModalClose()}
            >
              <img
                alt="..."
                src={
                  require("../../assets/img/icons/common/Vector.png").default
                }
              />
            </button>
            <div className="SuppInboxText">Support Inbox</div>
          </div>
          <div className="SupportBoxContainor">
            <div
              onClick={() => {
                resetModal();
                setTab(0);
                setMyMessageId(null);
                setAllMessageId(null);
                setOpenMsgModal(false);
              }}
            >
              <div
                className={
                  tab === 0
                    ? "buttonTabs11 underLine cursor-pointer"
                    : "buttonLight11 cursor-pointer"
                }
              >
                My Inbox
              </div>
            </div>
            <div
              onClick={() => {
                resetModal();
                setTab(1);
                setMyMessageId(null);
                setAllMessageId(null);
                setOpenMsgModal(false);
              }}
            >
              <div
                className={
                  tab === 1
                    ? "buttonTabs11 underLine cursor-pointer"
                    : "buttonLight11 cursor-pointer"
                }
              >
                All Chats
              </div>
            </div>
          </div>
        </div>
        <div className="SuppDivider">
          <div className="divider"></div>
        </div>
        {tab === 0 && (
          <div ref={myChatRef} className="PrimaryBox11">
            <div>
              {/* {
                loading === true ? (
                  <div><ReactLoading
                    type={"spin"}
                    color={"#ff6700"}
                    height={30}
                    width={30} />
                  </div>
                ) : ( */}
              <InBoxServiceProvider>
                <MyChat
                  chatType="myChat"
                  adminId={adminId}
                  admin={admin}
                  messageId={myMessageId}
                  setMyMessageId={setMyMessageId}
                  setAllMessageId={setAllMessageId}
                  openMsgModal={openMsgModal}
                  setOpenMsgModal={setOpenMsgModal}
                  scroll={myScroll}
                  setScroll={setMyScroll}
                  chatRef={myChatRef?.current}
                />
              </InBoxServiceProvider>
              {/* )
              } */}
            </div>
          </div>
        )}
        {tab === 1 && (
          <div ref={allChatRef} className="PrimaryBox11">
            {openMsgModal ? null : (
              <div className="">
                <div className="SuppAllChatAgent">
                  <div className="SuppAllChantAgentText">Admin Agent</div>
                  <div className="SuppAllChatArrows">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/icons/common/interChangeArrows.png")
                          .default
                      }
                    />
                  </div>
                  <div className="SuppAllChantAgentText">App User</div>
                </div>
              </div>
            )}
            {/* {
            loading === true ? (

              <div><ReactLoading
                type={"spin"}
                color={"#ff6700"}
                height={30}
                width={30} />
              </div>
              ) : ( 
              <AllChat chatType="allChat" loading={() => { setLoading(false) }} admin={admin} />
              */}
            <InBoxServiceProvider>
              <AllChat
                chatType="allChat"
                admin={admin}
                openMsgModal={openMsgModal}
                setOpenMsgModal={setOpenMsgModal}
                messageId={allMessageId}
                setMyMessageId={setMyMessageId}
                setAllMessageId={setAllMessageId}
                scroll={allScroll}
                setScroll={setAllScroll}
                chatRef={allChatRef?.current}
              />
            </InBoxServiceProvider>
            {/* )
          } */}
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportModal;
