import React from "react";
import { Button, CardHeader, FormGroup, Input, Modal } from "reactstrap";
import PaidIcon from "@mui/icons-material/Paid";
import { toast } from "react-toastify";

const VerifyTransferModal = ({
  bill,
  isOpen,
  message,
  invoice,
  setState,
  isloading,
  handleModal,
  handleLoader,
  editBankBilling,
  handlePayedOnDate,
  bankBillingRequest,
  handleMessageField,
  editBankBillingRejected,
}) => {
  return (
    <div>
      <Modal
        className="modal-dialog-centered BillingInvoiceModal2"
        toggle={(e) => {
          handleModal();
        }}
        isOpen={isOpen}
      >
        <CardHeader className="border-0">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <i
              size="lg"
              style={{
                color: "#FF8A3C",
                paddingRight: "4px",
                fontSize: "3.125vw",
                marginTop: "-15px",
              }}
              // className="fas fa-graduation-cap"
            >
              <PaidIcon
                style={{
                  fontSize: "3.125vw",
                  color: "#FF8A3C",
                  paddingRight: "4px",
                  fontSize: "3.125vw",
                  marginTop: "-5px",
                }}
              />
            </i>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                marginLeft: "5px",
                textAlign: "left",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ marginBottom: "-5px" }}>
                  {" "}
                  Verify Bank Transfer Request{" "}
                </h5>
                <i
                  style={{
                    marginLeft: "187px",
                    paddingTop: "3px",
                    color: "#9A9A9A",
                  }}
                  onClick={(e) => {
                    handleModal();
                  }}
                  className="fas fa-times"
                ></i>
              </div>

              <p
                style={{
                  color: "#9A9A9A",
                  textAlign: "left",
                  fontSize: "75%",
                }}
              >
                Cross check the billing details from attached bank transection
              </p>
            </div>
          </div>
        </CardHeader>
        <div>
          <div
            style={{
              justifyContent: "space-around",
              display: "flex",
              backgroundColor: "#F6F9FC",
              padding: "1.042vw",
            }}
          >
            <div>
              <div>
                <h5 style={{ color: "#757575" }}>Bank Name </h5>
                <h5
                  style={{
                    fontSize: "95%",
                    color: "#454545",
                    fontWeight: "bold",
                  }}
                >
                  {bankBillingRequest?.bank}
                </h5>
              </div>
              <div>
                <h5 style={{ color: "#757575", marginTop: "0.521vw" }}>
                  Invoice{" "}
                </h5>
                <h5
                  style={{
                    fontSize: "95%",
                    color: "#454545",
                    fontWeight: "bold",
                  }}
                >
                  #{bankBillingRequest?.invoice?.substring(15).toUpperCase()}
                  {/* {selectedQualification?.degree} */}
                  {/* {"Science"}
                              {"Primary 1"} */}
                </h5>
              </div>
              <div>
                <h5 style={{ color: "#757575", marginTop: "0.521vw" }}>
                  Amount
                </h5>
                <h5
                  style={{
                    fontSize: "95%",
                    color: "#454545",
                    fontWeight: "bold",
                  }}
                >
                  $
                  {bankBillingRequest?.amount_received
                    ? bankBillingRequest?.amount_received / 100
                    : 0}
                </h5>
              </div>
              <div>
                <h5 style={{ color: "#757575", marginTop: "0.521vw" }}>
                  Paid Date
                </h5>
                <h5
                  style={{
                    fontSize: "95%",
                    color: "#454545",
                    fontWeight: "bold",
                  }}
                >
                  {handlePayedOnDate(bankBillingRequest?.payedOn)}
                </h5>
              </div>
            </div>
            <div style={{ marginLeft: "100px" }}>
              <h5>REVIEW ATTACHMENT</h5>

              <div className="con">
                <img
                  className="transfterDocumentImg"
                  src={bankBillingRequest?.document}
                  alt="document"
                />
                <div className="overlay">
                  <p style={{ fontSize: "0.833vw", fontWeight: "700" }}>
                    BankTransfer.pdf
                  </p>
                  <a href={bankBillingRequest?.document} target="_blank">
                    <p
                      style={{
                        fontSize: "0.729vw",
                        color: "#FFCB95",
                        fontWeight: "400",
                      }}
                    >
                      {/* {bankBillingRequest.document} */}
                      (Click to review)
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <div
                style={{
                  float: "right",
                  paddingTop: "8px",
                  marginLeft: "77%",
                  paddingRight: "15px",
                }}
              >
                <Button
                  color="success"
                  type="button"
                  disabled={isloading}
                  onClick={() => {
                    editBankBilling(bill?.jobId, invoice.invoice, bill);
                    handleLoader(true);
                  }}
                >
                  Approve
                </Button>
              </div>

              <div>
                <h5 style={{ marginLeft: "1.302vw" }}>
                  Reason of Rejection (Required)
                </h5>
              </div>

              <div
                style={{
                  marginLeft: "1.302vw",
                  marginBottom: "0.521vw",
                  width: "185%",
                  height: "50%",
                }}
              >
                <FormGroup
                  style={{ width: "50%", height: "20%" }}
                  className="mb-0"
                >
                  <Input
                    onChange={(e) => handleMessageField(e.target.value)}
                    type="textarea"
                    style={{
                      wordWrap: "break-word",
                      height: "100px",
                    }}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="invoiceTransferActions">
              <Button
                style={{ backgroundColor: "#F5365C", color: "#fff" }}
                type="button"
                disabled={isloading}
                onClick={() => {
                  if (message.length > 0) {
                    editBankBillingRejected(
                      bill?.jobId,
                      invoice.invoice,
                      message
                    );
                    handleLoader(true);
                  } else {
                    return toast.error("Rejection Reason is required!");
                  }
                  // setState({ isloading: true });
                  // fromFunction()
                  // this.handleOpenModel2()
                }}
              >
                Reject
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VerifyTransferModal;
