/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import api from "../services/api";

// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  // Progress,
  // Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { isEmpty } from "lodash";
import RevenueGraph from "components/RevenueGraph";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [graphType, setGraphType] = useState("month");
  const [dashboardStats, setDashboardStats] = useState({});
  const [billing, setBilling] = useState([]);
  const [tutorPayableSalaries, setTutorPayableSalaries] = useState([]);
  const [revenueData, setRevenueData] = useState(null);


  const [stats, setStats] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Tuition Stats",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        maxBarThickness: 10,
      },
    ],
  });

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  async function getDashboardStats() {
    try {
      const dashboardStats = await api.getDashboardStats();
      setTutorPayableSalaries(dashboardStats.data.totalSalaries?.total);
      setDashboardStats(dashboardStats.data);
    } catch (e) {}
  }



  
  async function getStats() {
    try {
      const stats1 = await api.getTuitionStats();
      setStats(stats1.data);
    } catch (e) {}
  }

  useEffect(() => {
    getStats();
    getDashboardStats();
  }, []);

  return (
    <div style={{ backgroundColor: "#FFF8F0" }}>
      <Header
        cards
        dashboardStats={dashboardStats}
        tutorPayableSalaries={tutorPayableSalaries}
      />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card
              className="bg-gradient-default shadow"
              style={{ minHeight: "300px", height: "100%" }}
            >
              <CardHeader className="bg-transparent" style={{ margin: 0 }}>
                <Row className="align-items-center" style={{ width: "100%" }}>
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <h2 className="text-white mb-0">Net Revenue</h2>
                      <p data-tip="Net Revenue =  Total Revenue - Total Salaries">
                        <i
                          className="fas fa-info-circle icon-cog"
                          style={{
                            color: "white",
                            paddingLeft: "20px",
                            marginTop: "6px",
                            fontSize: "120%",
                          }}
                        ></i>
                      </p>
                      <ReactTooltip />
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div
                  className="chart"
                  style={{ minHeight: "300px", height: "100%" }}
                >
                    <RevenueGraph
                    setTutorPayableSalaries={setTutorPayableSalaries}
                    />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card
              className="shadow"
              style={{ minHeight: "300px", height: "100%" }}
            >
              <CardHeader className="bg-transparent" style={{ margin: 0 }}>
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      OVERVIEW
                    </h6>
                    <h2 className="mb-0">Tuitions started</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div
                  className="chart"
                  style={{ minHeight: "300px", height: "100%" }}
                >
                  <Bar data={stats} options={chartExample2.options} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Index;
