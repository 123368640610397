import React, { useState, useEffect, createRef, useRef } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import {
  Card,
  Container,
  Row,
  Modal,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import { messagesService } from "./messageBoxFBHook";
import ReactLoading from "react-loading";
import Avatar from "@mui/material/Avatar";
import { Path } from "services/Path";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, firebaseStorage } from "./firebase-config";

export const MsgBox = ({
  admin,
  chatType,
  chatInfo,
  chatId,
  closeModal,
  resetModal,
}) => {
  const [messageContent, setMessageContent] = useState("");
  const [loading, setLoading] = useState(false);

  // isLoading;
  const { messages, isLoading, addMessage } = messagesService();
  const contentIDS = useRef(null);
  const userId = chatId?.substring(25);
  const adminId = chatId?.substring(0, 24);

  const attachmentRef = useRef(null);

  useEffect(() => {
    handleScroll();
  }, [messages, messageContent]);

  const handleScroll = () => {
    contentIDS.current?.scrollIntoView();
  };

  const handleSendMessage = () => {
    let content = messageContent,
      fromName = `${admin?.name}`,
      idFrom = `${adminId}`,
      idTo = `${userId}`;
    addMessage(content, fromName, idFrom, idTo);
    setMessageContent("");
  };

  const dateFromTimestamp = (timestamp) => {
    let timestamp1 = new Date(timestamp * 1);
    timestamp1 = timestamp1?.toLocaleTimeString();
    timestamp1 = timestamp1.substr(0, 4) + timestamp1.substr(7, 9);
    let timestamp2 = new Date(timestamp * 1);
    let month1 = timestamp2.getMonth();
    let year1 = timestamp2.getFullYear();
    year1 = String(year1).substr(2, 3);
    timestamp2 = timestamp2?.getDate();
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    month1 = month[month1];
    return timestamp1 + " - " + timestamp2 + " " + month1 + ", " + year1;
  };

  function handleAdminLabelCheck(chatAdmin) {
    if (chatAdmin == admin?._id) {
      return true;
    } else {
      return false;
    }
  }

  const handleAttachmentUpload = (e) => {
    const inputFile = e.target?.files;
    handleUpload(inputFile[0]);
  };

  const handleUpload = async (file) => {
    if (file) {
      try {
        setLoading(true);
        // Upload the file to Firestore Storage
        const storageRef = ref(firebaseStorage, `chat-file/${file.name}`);

        let folderName = Path.QUALIFICATION?.includes("dev")
          ? "Dev-Chat-Files"
          : "Live-Chat-Files";

        await uploadBytes(storageRef, file);

        // Get the download URL of the uploaded file
        const downloadURL = await getDownloadURL(storageRef);

        // Add file information to Firestore
        const filesCollection = collection(db, folderName);
        await addDoc(filesCollection, {
          fileName: `${userId}/chat-file/${file.name}`,
          downloadURL: downloadURL,
          timestamp: serverTimestamp(),
        });
        let content = downloadURL,
          fromName = `${admin?.name}`,
          idFrom = `${adminId}`,
          idTo = `${userId}`,
          fileType = 1;
        addMessage(content, fromName, idFrom, idTo, fileType);
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error("Error uploading file:", error);
      }
    } else {
      console.warn("No file selected for upload.");
    }
  };

  return (
    <div className="PrimaryBox22">
      <div className="PrimaryBox33">
        <div>
          <div
            className="BackToChat"
            style={{ cursor: "pointer" }}
            onClick={() => {
              closeModal();
            }}
          >
            <div className="SuppAllChatArrows">
              <img
                alt="..."
                src={
                  require("../../../assets/img/icons/common/backcoolicon.png")
                    .default
                }
              />
              {chatType === "allChat" ? (
                <div className="BackToChatTextBold">Back to All Chat</div>
              ) : (
                <div className="BackToChatTextBold">Back to My Chat</div>
              )}
            </div>
          </div>
          <div>
            <div className="SuppDivider">
              <div className="divider1"></div>
            </div>
            <div className="SuppAllChatAgent1">
              <div className="LeftSuppAllChat">
                <div className="ProfilePicSuppMyInbox">
                  <Avatar
                    className="ProfilePicSuppMyInbox1"
                    alt={chatInfo?.adminNameList?.[0]}
                    src="/"
                  />
                </div>
                <div className="AgentAndTitleBoxSupp">
                  <div className="ChatSenderSuppMyInbox">
                    {chatInfo?.adminNameList?.[0]}
                  </div>
                  {handleAdminLabelCheck(chatInfo?.adminList[0]) == true ? (
                    <div className="MeLabelCoverBox">
                      <div className="MeLabelSuppChat">Me</div>
                    </div>
                  ) : (
                    <div className="SuperAdminLabelCoverBox">
                      <div className="SuperAdminLabelSuppChat">Admin</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="SuppAllChatArrows">
                <img
                  alt="..."
                  src={
                    require("../../../assets/img/icons/common/VectorGroup.png")
                      .default
                  }
                />
              </div>
              <div className="RightSuppAllChat1">
                <div className="AgentAndTitleBoxSupp2">
                  <div className="ChatSenderSuppMyInbox2">
                    {chatInfo?.appUserName}
                  </div>

                  {chatInfo?.appUserType === "Tutee" ? (
                    <div className="TuteeLabelCoverBox support-userType">
                      <div className="TuteeLabelCover">TUTEE</div>
                    </div>
                  ) : (
                    <div className="TUTORLabelSuppChatBox support-userType">
                      <div className="TUTORLabelSuppChat">TUTOR</div>
                    </div>
                  )}
                </div>
                <div className="ProfilePicSuppMyInbox">
                  <Avatar
                    className="ProfilePicSuppMyInbox1"
                    alt={chatInfo?.appUserName}
                    src={chatInfo?.appUserPhotoUrl}
                  />
                </div>
              </div>
            </div>
            <div className="SuppDivider">
              <div className="divider1"></div>
            </div>
          </div>
        </div>
        <div className="SuppAllChatMainBox">
          <div
            className={
              chatType === "allChat" ? "SuppChatMsgBox3" : "SuppChatMsgBox"
            }
          >
            {messages?.map((message, i = 0) => {
              {
                return (
                  <div
                    key={message?.timestamp}
                    ref={contentIDS}
                    className="content"
                  >
                    {message.idFrom == userId ? (
                      <div className="SuppChatMsgRecived">
                        {/* <div className="SuppChatMsgRecivedLeft">
                                                        <div className="ProfilePicSuppMyInbox">
                                                            <img
                                                                className="ProfilePicSuppMyInbox1"
                                                                alt="..."
                                                                src={
                                                                    require("../../../assets/img/theme/dummyImg.png")
                                                                        .default
                                                                }
                                                            />
                                                        </div>
                                                    </div> */}
                        <div className="SuppChatMsgRecivedRight">
                          <div className="SuppChatMsgRecivedMsgBox">
                            <p className="SuppChatMsgRecivedMsg">
                              {/* {message?.content} */}
                              {message?.content &&
                              message?.content.includes(
                                "https://firebasestorage.googleapis"
                              ) ? (
                                <div>
                                  <img
                                    objectFit="contain"
                                    width="424px"
                                    height="auto"
                                    src={message?.content}
                                  />
                                </div>
                              ) : (
                                message?.content
                              )}
                            </p>
                          </div>
                          <div className="SuppChatMsgRecivedMsgTime">
                            <div className="SuppChatMsgRecivedMsgTimeText">
                              {dateFromTimestamp(message?.timestamp)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="SuppChatMsgSent">
                        <div className="SuppChatMsgRecived">
                          <div className="SuppChatMsgRecivedRight">
                            {message.type === 0 ? (
                              <div className="SuppChatMsgRecivedMsgBox1">
                                <p className="SuppChatMsgRecivedMsg1">
                                  {/* {message?.content} */}
                                  {message?.content &&
                                  message?.content.includes(
                                    "https://firebasestorage.googleapis"
                                  ) ? (
                                    <div>
                                      <img
                                        objectFit="contain"
                                        width="424px"
                                        height="auto"
                                        src={message?.content}
                                      />
                                    </div>
                                  ) : (
                                    message?.content
                                  )}
                                </p>
                              </div>
                            ) : (
                              <img
                                href={message.content}
                                className="upload-chat-file"
                                src={message.content}
                                alt="file"
                              />
                            )}

                            <div className="SuppChatMsgRecivedMsgTime">
                              {/* {message && message.fromName && */}
                              {chatInfo?.adminNameList?.[0] !==
                                message.fromName &&
                              admin?._id !== message.idFrom &&
                              admin?._id !== message.idFrom ? (
                                <p className="SuppChatMsgRecivedMsgTimeTextAndRepliedBy">
                                  <span>
                                    {" "}
                                    <span>Replied by: </span>
                                    <span style={{ fontWeight: 700 }}>
                                      {message?.fromName}
                                    </span>
                                  </span>
                                  <span>
                                    {dateFromTimestamp(message?.timestamp)}
                                  </span>
                                </p>
                              ) : (
                                <div className="SuppChatMsgRecivedMsgTimeText1">
                                  {dateFromTimestamp(message?.timestamp)}
                                </div>
                              )}
                            </div>
                          </div>
                          {/* <div className="SuppChatMsgSentLeft">
                                                        <div className="ProfilePicSuppMyInbox">
                                                            <img
                                                                className="ProfilePicSuppMyInbox1"
                                                                alt="..."
                                                                src={
                                                                    require("../../../assets/img/theme/dummyImg.png")
                                                                        .default
                                                                }
                                                            />
                                                        </div>
                                                    </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
            })}
            {(isLoading || loading) && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ReactLoading
                  type={"spin"}
                  color={"#ff6700"}
                  height={30}
                  width={30}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* )
            } */}

      <div className="SuppChatBoxMsgMainBox">
        <div className="SuppChatMsgRecivedLeft">
          <div
            style={{ marginRight: "50px" }}
            className="ProfilePicSuppMyInbox"
          >
            {/* <img
                            className="ProfilePicSuppMyInbox1"
                            alt="..."
                            src={
                                require("../../../assets/img/theme/dummyImg.png")
                                    .default
                            }
                        /> */}
          </div>
        </div>
        <div className="SuppChatBoxMsgBox">
          <div className="SuppChatBoxMsgBoxTop">
            <FormGroup className="SuppChatBoxMsgBoxTop1">
              {/* <Input onChange={(e) => setMessageContent(e.target.value)} type="text" style={{ wordWrap: 'break-word', height: '100px' }} /> */}
              <Input
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
                type="textarea"
                placeholder="Type your message here"
                className="SuppChatBoxMsgBoxTop2"
              />
            </FormGroup>
          </div>
          <div className="SuppChatBoxMsgBoxBottom">
            <div className="SuppDivider">
              <div className="divider"></div>
            </div>
            <div className="SuppChatBoxMsgBoxBottom1">
              <div className="SuppChatBoxMsgBoxBottom11">
                <div className="SuppChatBoxMsgBoxBottomText">Replying as</div>
                <div className="SuppChatBoxMsgBoxBottomTextBold">
                  {admin?.name}
                </div>
                <div className="MeLabelCoverBox">
                  <div className="MeLabelSuppChat">Me</div>
                </div>
              </div>
              <div className="FileSharingIcon">
                {/* <img
                                    alt="..."
                                    src={
                                        require("../../../assets/img/icons/common/VectorFileSharing.png")
                                            .default
                                    } /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="SuppChatBoxSendBtnMain">
          <img
            className="attach-file-icon"
            alt="attach-file-icon"
            src="/attach-file.png"
            onClick={() => {
              attachmentRef.current?.click();
            }}
          />

          <input
            hidden
            type="file"
            id="attachment"
            ref={attachmentRef}
            onChange={handleAttachmentUpload}
            accept=".jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PNG"
          />

          <div
            className="SuppChatBoxSendBtn"
            onClick={() => {
              handleSendMessage();
            }}
          >
            <div className="SuppAllChatArrows">
              <img
                alt="..."
                src={
                  require("../../../assets/img/icons/common/SupportSendMsg22.png")
                    .default
                }
              />
            </div>
            <div className="SuppChatBoxSendBtnText">SEND</div>
          </div>
        </div>
      </div>
    </div>
  );
};
