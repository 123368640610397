/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import StatsCard from "../Cards/DashoardStatsCard";
import StatsCardSmall from "../Cards/DashboardSmallCard";
import cardlogo from "../../assets/img/theme/Ellipse.jpg";
import { useState, useEffect } from "react";
import "./index.css";
import Loading from "react-loading";
import { useLocation } from "react-router-dom";

const Header = ({ cards, dashboardStats, tutorPayableSalaries }) => {
  const [isLoading, setIsLoading] = useState(false);

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  useEffect(async () => {
    setIsLoading(true);
    numberWithCommas();
    setIsLoading(false);
  }, []);
  const size = useWindowSize();

  const { pathname } = useLocation();

  const pathToExclude = ["/admin/biling", "/admin/vouchers"];

  return (
    <>
      <div
        className={
          window.innerWidth <= 1366
            ? `header bg-gradient-info pb-7 pt-4 ${
                pathToExclude.includes(pathname) && "headerRow"
              }`
            : `header bg-gradient-info pb-8 pt-5 ${
                pathToExclude.includes(pathname) && "headerRow"
              }`
        }
      >
        {pathToExclude.includes(pathname) ? (
          <></>
        ) : (
          <hr
            style={
              window.innerWidth <= 1366
                ? {
                    marginBottom: "3rem",
                    marginTop: "3rem",
                    position: "relative",
                    top: "17px",
                  }
                : {
                    height: "1px",
                    borderWidth: 0,
                    color: "rgba(255, 255, 255, 0.2)",
                    marginBottom: "4rem",
                    marginTop: "3rem",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    width: "90%",
                    position: "relative",
                    top: "17px",
                  }
            }
          />
        )}
        {cards ? (
          <Container fluid style={{ marginBottom: 22 }}>
            <Row className="header-card">
              <Col
                xl={size.width < 1800 ? (size.width < 1630 ? "4" : "3") : "3"}
                lg="6"
                md="12"
                sm="12"
                xs="12"
                className={
                  window.innerWidth <= 1366 ? "headerCol66" : "headerCol"
                }
              >
                <StatsCard
                  title="TUTEE"
                  total={
                    isLoading ? (
                      <Loading color={"#E5E5E5"} width={25} height={25} />
                    ) : dashboardStats?.tutee?.total ? (
                      numberWithCommas(dashboardStats?.tutee?.total)
                    ) : (
                      0
                    )
                  }
                  icon={cardlogo}
                  perc={
                    isLoading ? (
                      <Loading color={"#E5E5E5"} width={25} height={25} />
                    ) : dashboardStats?.tutee?.increasePerc ? (
                      `${Math.round(dashboardStats?.tutee?.increasePerc)}%`
                    ) : (
                      0
                    )
                  }
                  percText={
                    size.width <= 1444 ? "this month" : "Increased this month"
                  }
                  percIcon="fa fa-arrow-up"
                  width={size.width}
                  height={size.height}
                />
              </Col>
              <Col
                xl={size.width < 1800 ? (size.width < 1630 ? "4" : "3") : "3"}
                lg="6"
                md="12"
                sm="12"
                xs="12"
                className={
                  window.innerWidth <= 1366 ? "headerCol66" : "headerCol"
                }
              >
                <StatsCard
                  title="TUTOR"
                  total={
                    isLoading ? (
                      <Loading color={"#E5E5E5"} width={25} height={25} />
                    ) : dashboardStats?.tutors?.total ? (
                      numberWithCommas(dashboardStats?.tutors?.total)
                    ) : (
                      0
                    )
                  }
                  icon={cardlogo}
                  perc={
                    isLoading ? (
                      <Loading color={"#E5E5E5"} width={25} height={25} />
                    ) : dashboardStats?.tutors?.increasePerc ? (
                      `${Math.round(dashboardStats?.tutors?.increasePerc)}%`
                    ) : (
                      0
                    )
                  }
                  percText={
                    size.width <= 1444 ? "this month" : "Increased this month"
                  }
                  percIcon="fa fa-arrow-up"
                />
              </Col>

              <Col
                xl={size.width < 1800 ? (size.width < 1630 ? "4" : "3") : "2"}
                lg="4"
                md="12"
                sm="12"
                xs="12"
                className={
                  window.innerWidth <= 1366 ? "headerCol1366" : "headerCol1"
                }
              >
                <StatsCardSmall
                  title={`SALARIES - ${
                    dashboardStats?.totalSalaries?.month || 0
                  }`}
                  total={`$${tutorPayableSalaries || 0}`}
                  perc=""
                  percIcon=""
                  small
                />
              </Col>

              <Col
                xl={size.width < 1800 ? (size.width < 1630 ? "4" : "3") : "2"}
                lg="4"
                md="12"
                sm="12"
                xs="12"
                className={
                  window.innerWidth <= 1366 ? "headerCol1366" : "headerCol1"
                }
              >
                <StatsCardSmall
                  title="TOTAL TUITION"
                  total={
                    numberWithCommas(dashboardStats?.totalTuitions?.total) || 0
                  }
                  // perc={`${
                  //   Math.round(dashboardStats?.totalTuitions?.increasePerc) || 0
                  // }%`}
                  // percText={
                  //   size.width <= 1444 ? "this month" : "Increased this month"
                  // }
                  // percIcon="fa fa-arrow-up"
                  small
                />
              </Col>

              <Col
                xl={size.width < 1800 ? (size.width < 1630 ? "4" : "3") : "2"}
                lg="4"
                md="12"
                sm="12"
                xs="12"
                className={
                  window.innerWidth <= 1366 ? "headerCol1366" : "headerCol1"
                }
              >
                <StatsCardSmall
                  title="ACTIVE TUITION"
                  total={
                    numberWithCommas(dashboardStats?.activeTuitions?.total) || 0
                  }
                  // perc={`${
                  //   Math.round(dashboardStats?.activeTuitions?.increaseNum) || 0
                  // }`}
                  // percText={
                  //   size.width <= 1444 ? "this month" : "Started this month"
                  // }
                  // percIcon="fa fa-arrow-up"
                  small
                />
              </Col>
            </Row>
            {/* </div> */}
          </Container>
        ) : null}
      </div>
    </>
  );
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default Header;
