import React, { useState, useEffect, useContext } from "react";
import { MsgBox } from "./msgBox";
import MessageServiceProvider from "./messageBoxFBHook";
import { adminService } from "./inBoxFBHook";
import Avatar from "@mui/material/Avatar";
import { SupportContext } from "../../../context/supportContext";

export const AllChat = ({
  admin,
  messageId,
  setMyMessageId,
  setAllMessageId,
  openMsgModal,
  setOpenMsgModal,
  scroll,
  setScroll,
  chatRef,
}) => {
  const [doc, setDoc] = useState({});
  const { data, isLoading } = adminService();
  const { closeModal, modalInfo, resetModal } = useContext(SupportContext);
  useEffect(() => {
    if (messageId && modalInfo?.adminId && data && data.length) {
      const temp = data?.find((doc) => doc?.id === messageId);
      setDoc(temp);
      setOpenMsgModal(true);
    }
  }, [messageId, data]);
  function handleAdminLabelCheck(chatAdmin) {
    if (chatAdmin == admin?._id) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <>
      {openMsgModal === false ? (
        data?.map((doc, i = 0) => {
          return (
            <div key={doc?.id}>
              <div className="SuppDivider">
                <div className="divider"></div>
              </div>
              <div className="PrimaryBox">
                <div className="supportChatMyInboxDivider">
                  <div
                    className="supportChatMyInbox"
                    onClick={() => {
                      console.log(chatRef);
                      console.log("chatRef");
                      setScroll(chatRef);
                      setOpenMsgModal(true);
                      setDoc(doc);
                    }}
                  >
                    <div className="LeftSuppAllChat">
                      <div className="ProfilePicSuppMyInbox">
                        <Avatar
                          className="ProfilePicSuppMyInbox1"
                          alt={doc?.adminNameList[0]}
                          src="/"
                        />
                      </div>
                      <div className="AgentAndTitleBoxSupp">
                        <div className="ChatSenderSuppMyInbox">
                          {doc?.adminNameList[0]}
                        </div>
                        {handleAdminLabelCheck(doc?.adminList[0]) == true ? (
                          <div className="MeLabelCoverBox">
                            <div className="MeLabelSuppChat">Me</div>
                          </div>
                        ) : (
                          <div className="SuperAdminLabelCoverBox">
                            <div className="SuperAdminLabelSuppChat">Admin</div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="RightSuppAllChat">
                      <div className="AgentAndTitleBoxSupp2">
                        <div className="ChatSenderSuppMyInbox2">
                          {doc?.appUserName}
                        </div>
                        {doc?.appUserType === "Tutee" ? (
                          <div className="TuteeLabelCoverBox support-userType">
                            <div className="TuteeLabelCover">TUTEE</div>
                          </div>
                        ) : (
                          <div className="TUTORLabelSuppChatBox support-userType">
                            <div className="TUTORLabelSuppChat">TUTOR</div>
                          </div>
                        )}
                      </div>
                      <div className="ProfilePicSuppMyInbox">
                        <Avatar
                          className="ProfilePicSuppMyInbox1"
                          alt={doc?.appUserName}
                          src={doc?.appUserPhotoUrl}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="SuppDivider">
                  <div className="divider"></div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <MessageServiceProvider>
          <MsgBox
            chatType="allChat"
            admin={admin}
            chatInfo={doc}
            chatId={doc.id}
            resetModal={() => {
              setMyMessageId(null);
              setAllMessageId(null);
              resetModal();
            }}
            closeModal={() => {
              setOpenMsgModal(false);
              resetModal();
              setDoc({});
            }}
          />
          {/* <MsgBox chatType="myChat" loggedUser={loggedUser} chatInfo={doc} chatId={docId} closeModal={() => setOpenMsgModal(false)} /> */}
        </MessageServiceProvider>
      )}
    </>
  );
};
