import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "reactstrap";
import api from "../../../services/api";
const ViewTutorsBillingModal = ({
  invoiceMonth = "",
  isOpen,
  handleModal,
  handleDownloadPdf,
  selectedMonth,
  salaries
}) => {
  return (
    <div>
      <Modal
        className="modal-dialog123"
        toggle={(e) => {
          handleModal();
        }}
        fullscreen
        contentClassName="tutorBillingModal"
        isOpen={isOpen}
      >
        <div className="tutorBillingModalContainer" id="divToPrint">
          <div className="downloadPdfHeaderRow">
            <div>
              <h1 style={{marginBottom:'12px'}}>{`Tutor Salaries (${
                invoiceMonth ? invoiceMonth : null
              })`}</h1>
              <h3>{`Total Salaries: ${
                salaries && salaries.length
                  ? `$${salaries.reduce((sum, salary) => sum + Number(salary?.totalSalary || 0), 0)}`
                  : '$0'
              }`}</h3>
              <h3 style={{marginBottom:'18spx'}}>{`Generated Date: ${new Date().getDate()} ${selectedMonth}`}</h3>
            </div>
            <i
              style={{
                marginLeft: "187px",
                paddingTop: "3px",
                color: "#9A9A9A",
                cursor: "pointer",
              }}
              onClick={(e) => {
                handleModal();
              }}
              className="fas fa-times"
            />
          </div>
          {salaries &&
            salaries.length > 0 &&
            salaries.map((tutorData) => (
              <TutorClassesInfo
                key={tutorData.tutorId}
                selectedMonth={selectedMonth}
                invoiceMonth={invoiceMonth || tutorData.month}
                tutorName={tutorData.tutorName}
                tutorClassesInfo={{
                  tutionClasses: tutorData.bills,
                  sumOfAllTutionClassesSalary: tutorData?.totalSalary,
                  bankInfo:
                    tutorData.tutorBankDetail.length > 0
                      ? tutorData.tutorBankDetail.join(", ")
                      : "No bank details available",
                }}
                salaries={salaries}
              />
            ))}
        </div>
        <div className="downloadPdfBlock">
          <Button className="btn_after_button" onClick={handleDownloadPdf}>
            Download PDF
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ViewTutorsBillingModal;

const TutorClassesInfo = ({
  tutorName,
  invoiceMonth,
  tutorClassesInfo,
  salaries,
  selectedMonth,
}) => {
  const [openModel, setOpenModel] = useState(false);
  const [modelData, setModelData] = useState({});
  const { tutionClasses, sumOfAllTutionClassesSalary, bankInfo } =
    tutorClassesInfo;
  const handleCloseModal = () => {
    setModelData({});
    setOpenModel(false);
  };

  return (
    <div>
      <div className="tutorInfoRow">
        Tutor Name: <span className="font-bold">{tutorName}</span>
      </div>
      <div className="tutorInfoRow">
        Bank Details: <span className="font-bold">{bankInfo}</span>
      </div>

      <div className="tutorInfoRow">
        <Table className="align-items-center table-flush table-Pad" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Tuition</th>
              <th scope="col">Invoice</th>
              <th scope="col">Tutee</th>
              <th scope="col" style={{ textAlign: "center" }}>
                Total Classes
              </th>
              <th scope="col" style={{ textAlign: "center" }}>
                Salary
              </th>
            </tr>
          </thead>
          <tbody>
            {tutionClasses.map((classInfo) => (
              <tr key={classInfo?.jobId}>
                <td>
                  <span>
                    {classInfo?.courseName}
                    <br />
                    {classInfo?.courseLevel}
                  </span>
                </td>
                <td>#{classInfo?.jobId.substring(17).toUpperCase()}</td>
                <td>{classInfo?.tuteeName}</td>
                <td
                  className="text-center cursor-pointer"
                  style={{
                    color: "#5E72E4",
                  }}
                  onClick={() => {
                    setModelData({ classInfo, tutorClassesInfo });
                    setOpenModel(true);
                  }}
                >
                  {classInfo?.monthClasses?.length} Classes
                </td>
                <td className="text-center">${classInfo.monthSalary}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className="text-end">
        Total Salary:&nbsp;
        <span className="font-bold">${sumOfAllTutionClassesSalary}</span>
      </div>
      <Modal
        className="modal-dialog-centered BillingInvoiceModal"
        toggle={handleCloseModal}
        isOpen={openModel}
      >
        <div className="BillingInvoiceModalBox">
          <div className="InvoiceClassesModalBox">
            <div className="InvoiceModalBox">
              <div className="ModalInvoiceText">
                <span style={{ fontWeight: 700 }}>
                  {/* #{modelData?.classInfo?.invoice.substring(15).toUpperCase()} */}
                </span>
              </div>
              <div>
                <img
                  className="cursor-pointer"
                  onClick={(e) => {
                    handleCloseModal();
                  }}
                  alt="..."
                  src={
                    require("../../../assets/img/icons/common/GroupCross.png")
                      .default
                  }
                />
              </div>
            </div>
            <div className="ModalClassesBox">
              <div className="ModalClassesBox1">
                <div className="ModalClassesBoxText45">Month</div>
                <div className="ModalClassesBoxTextBlue">{selectedMonth}</div>
              </div>
              <div className="ModalClassesBox1">
                <div className="ModalClassesBoxText45">Total Classes</div>
                <div className="ModalClassesBoxTextBlue">
                  {modelData?.classInfo?.monthClasses?.length}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "0.365vw",
              height: "33.26.5vw",
              overflowY: "scroll",
              marginTop: "41px",
            }}
            className="BillingInvoiceModalBoxTable PrimaryScrollQualification12121"
          >
            <Table
              className="align-items-center table-flush PrimaryScrollQualification12121"
              style={{ overflowY: "scroll" }}
              responsive
            >
              <thead
                className="thead-light sticky-top"
                style={{
                  position: "absolute",
                  width: "20.65vw",
                  zIndex: "1000",
                  top: "8vw",
                }}
              >
                <tr>
                  <th
                    scope="col"
                    style={{
                      paddingLeft: "1.563vw",
                      textAlign: "start",
                      fontSize: "0.625vw",
                      fontWeight: 700,
                      width: "10.325vw",
                    }}
                  >
                    Classes Date
                  </th>
                  <th
                    scope="col"
                    style={{
                      paddingRight: "1.563vw",
                      textAlign: "end",
                      fontSize: "0.625vw",
                      fontWeight: 700,
                      width: "10.325vw",
                    }}
                  >
                    Charge
                  </th>
                </tr>
              </thead>
              <tbody>
                {modelData?.classInfo?.monthClasses?.map((clas) => (
                  <tr>
                    <td
                      style={{
                        fontSize: "0.729vw",
                        fontWeight: 600,
                        color: "#9A9A9A",
                        paddingLeft: "1.563vw",
                        textAlign: "start",
                      }}
                      scope="row"
                    >
                      {clas.date}
                    </td>
                    <td
                      style={{
                        fontSize: "0.729vw",
                        fontWeight: 700,
                        color: "#9A9A9A",
                        paddingRight: "1.563vw",
                        textAlign: "end",
                      }}
                      scope="row"
                    >
                      {modelData?.classInfo?.courseName}
                      {", "}
                      {modelData?.classInfo?.courseLevel}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal>
    </div>
  );
};
