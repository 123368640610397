import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { chartExample1 } from "variables/charts";
import api from "../../services/api";
import moment from "moment";
import { isEmpty } from "lodash";

const GraphComponent = ({ setTutorPayableSalaries }) => {
  const [state, setState] = useState({
    graphMonths: [],
    graphData: [],
    allData: [],
  });
  const calculatePayableSalariesOfTuturs = (billing) => {
    const formattedData = {};
    const selectedMonth = moment(new Date()).format("MMMM, YYYY");

    billing?.result?.forEach((invoicesList) => {
      if (invoicesList.job[0].jobStatus !== 1 && !isEmpty(invoicesList.tutor)) {
        const tutorName = invoicesList?.tutor[0]?.name || "N/A";

        // Check if the tutor name is not already in the formattedData
        if (!formattedData[tutorName]) {
          formattedData[tutorName] = {
            bankInfo: !isEmpty(invoicesList?.tutorBank)
              ? `${invoicesList?.tutorBank[0]?.bankName}, ${invoicesList?.tutorBank[0]?.accountNumber}`
              : "N/A",
            tutionClasses: [],
            sumOfAllTutionClassesSalary: 0,
          };
        }

        invoicesList?.invoices
          .filter((invoice) => invoice.month === selectedMonth)
          .forEach((invoice) => {
            const tuitionName = invoicesList.job[0]?.courseName || "N/A";
            const invoiceNumber = `#${invoice.invoice} (${invoice.type})`;
            const tuteeName = `${invoicesList?.tutee[0]?.name || "N/A"} (${invoicesList?.tutee[0]?.email || ""
              })`;
            const totalBill = `$${invoice.amount || 0}`;
            const tutorSalary = `$${invoice.salary || 0}`;

            const tutionClassData = {
              tutionName: tuitionName,
              invoiceNumber: invoiceNumber,
              tuteeName: tuteeName,
              totalBill: totalBill,
              tutorSalary: tutorSalary,
            };

            // Add the tutionClassData to the tutor's tutionClasses
            formattedData[tutorName].tutionClasses.push(tutionClassData);

            // Update the sum of all tutor salaries
            formattedData[tutorName].sumOfAllTutionClassesSalary =
              parseFloat(formattedData[tutorName].sumOfAllTutionClassesSalary) +
              parseFloat(tutorSalary.slice(1));
          });
      }
    });

    let total = 0;

    Object.values(formattedData).map((item) => {
      total += parseFloat(item.sumOfAllTutionClassesSalary);
    });

    return total;
  };

  function filterInvoicesByAllMonths2(dataArray) {
    let today = new Date();
    let last6Months = new Date();
    last6Months.setMonth(last6Months.getMonth() - 6);

    let finalArray = [];

    const monthNames = {
      January: "Jan",
      February: "Feb",
      March: "Mar",
      April: "Apr",
      May: "May",
      June: "Jun",
      July: "Jul",
      August: "Aug",
      September: "Sept",
      October: "Oct",
      November: "Nov",
      December: "Dec",
    };

    let final = dataArray.reduce((filteredData, item) => {
      if (filteredData && item?.job[0]?.jobStatus !== 1) {
        item.invoices.forEach((invoice) => {
          const momentInvoiceDate = moment(invoice.month, 'MMMM, YYYY', true);
          if (!momentInvoiceDate.isValid()) {
            console.error('Invalid date:', invoice.month);
            return; // Skip this invoice
          }
          const invoiceDate1 = momentInvoiceDate.toDate();
          const invoiceDate = new Date(invoiceDate1)
          if (invoiceDate >= last6Months && invoiceDate <= today) {
            const fullMonth = invoice.month.split(",")[0].trim();
            const shortMonth = monthNames[fullMonth];
            if (!shortMonth) {
              console.error('Invalid shortMonth:', fullMonth);
              return; // Skip this invoice
            }
            if (!filteredData || !shortMonth || !filteredData[shortMonth]) {
              filteredData[shortMonth] = [];
            }
            filteredData[shortMonth].push(invoice);
          }
        });

      }
      return filteredData;
    }, {});
    const sortedMonths = Object.keys(final).sort(
      (a, b) => new Date(a + " 1, 2000") - new Date(b + " 1, 2000")
    );

    sortedMonths.forEach((shortMonth) => {
      const value = final[shortMonth];
      let totalSalary = 0;
      let totalBill = 0;
      let paid = 0;

      value.forEach((record) => {
        totalSalary += record.salary;
        totalBill += record.amount;
        paid += record.amount - record.remainingAmount;
      });

      finalArray.push({
        month: shortMonth,
        totalSalary,
        totalBill,
        paid,
        netRevenue: totalBill - totalSalary,
      });
    });

    const graphData = finalArray.map((item) => item.netRevenue);
    const graphMonths = finalArray.map((item) => item.month);

    setState((prev) => ({
      ...prev,
      graphMonths,
      graphData,
      allData: finalArray,
    }));
  }
  const getBillingInfo = async () => {
    try {
      const billing = await api.getBilling();
      let salaries = calculatePayableSalariesOfTuturs(billing.data);
      // setTutorPayableSalaries(salaries);
      filterInvoicesByAllMonths2(billing?.data?.result);
    } catch (e) {
      console.log(e);
      console.log('e');
    }
  };

  useEffect(() => {
    getBillingInfo();
  }, []);
  return (
    <RevenueGraph
      data={state.graphData}
      labels={state.graphMonths}
      allData={state.allData} />

  );
};

const RevenueGraph = ({ data = [], labels = [], allData }) => {
  const [state, setState] = useState(null);

  const updateDataOfGraph = () => {
    setState({
      data: {
        labels: labels,
        datasets: [
          {
            label: "Sales",
            data: data,
            maxBarThickness: 10,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (value) {
                  if (!(value % 10)) {
                    return "$" + value;
                    // return value;
                  }
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            title: function (item, data) {
              var label = item[0]?.label || "";
              var yLabel = item[0].yLabel;

              return `${label} ($${yLabel})`;
            },
            label: function (item, data) {
              var label = data.datasets[item.datasetIndex].label || "";
              // var yLabel = item.yLabel;
              var content = "";

              if (data.datasets.length > 1) {
                content += label;
              }

              let selectedData = allData.filter(
                (record) => record.month === item.label
              );

              if (selectedData)
                return [
                  `Total Bill: $${selectedData[0]?.totalBill || 0}\n`,
                  `Paid: $${selectedData[0]?.paid || 0}`,
                  `Tutor Salary: $${selectedData[0]?.totalSalary || 0}\n`,
                  // `Total Revenue : $${yLabel}\n`,
                ];
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    updateDataOfGraph();
  }, [data]);

  if (state?.data && state?.options) {
    return (
      <Line
        data={state.data}
        options={state.options}
        getDatasetAtEvent={(e) => console.log(e)}
      />
    );
  }
  return null;
};

export default GraphComponent;
